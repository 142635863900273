// modals
class Modal {
  constructor(options, modal) {
    let defaultOptions = {
      onOpen: () => {},
      onClose: () => {},
    };
    this.options = Object.assign(defaultOptions, options);
    this.modal = modal;
    this.speed = false;
    this.animation = false;
    this.isOpen = false;
    this.modalContainer = this.modal.querySelector(".modal__container");
    this.previousActiveElement = false;
    this.fixBlocks = document.querySelectorAll(".fix-block");
    this.focusElements = [
      "a[href]",
      "input",
      "button",
      "select",
      "textarea",
      "[tabindex]",
    ];
  }

  open() {
    this.previousActiveElement = document.activeElement;

    this.modal.style.setProperty("--transition-time", `${this.speed / 1000}s`);
    this.modal.classList.add("is-open");
    this.disableScroll();

    this.modalContainer.classList.add("modal-open");
    this.modalContainer.classList.add(this.animation);

    setTimeout(() => {
      this.modalContainer.classList.add("animate-open");
      this.options.onOpen(this);
      this.isOpen = true;
    }, this.speed);
  }

  close() {
    if (this.modalContainer) {
      this.modalContainer.classList.remove("animate-open");
      this.modalContainer.classList.remove(this.animation);
      this.modal.classList.remove("is-open");
      this.modalContainer.classList.remove("modal-open");

      this.enableScroll();
      this.options.onClose(this);
      this.isOpen = false;
    }
  }

  focusCatch(e) {
    const focusable = this.modalContainer.querySelectorAll(this.focusElements);
    const focusArray = Array.prototype.slice.call(focusable);
    const focusedIndex = focusArray.indexOf(document.activeElement);

    if (e.shiftKey && focusedIndex === 0) {
      focusArray[focusArray.length - 1].focus();
      e.preventDefault();
    }

    if (!e.shiftKey && focusedIndex === focusArray.length - 1) {
      focusArray[0].focus();
      e.preventDefault();
    }
  }

  focusTrap() {
    const focusable = this.modalContainer.querySelectorAll(this.focusElements);
    if (this.isOpen) {
      focusable[0].focus();
    } else {
    }
  }

  disableScroll() {
    let pagePosition = window.scrollY;
    this.lockPadding();
    document.body.classList.add("disable-scroll");
    document.body.dataset.position = pagePosition;
    document.body.style.top = -pagePosition + "px";
  }

  enableScroll() {
    let pagePosition = parseInt(document.body.dataset.position, 10);
    this.unlockPadding();
    document.body.style.top = "auto";
    document.body.classList.remove("disable-scroll");
    window.scroll({ top: pagePosition, left: 0 });
    document.body.removeAttribute("data-position");
  }

  lockPadding() {
    let paddingOffset = window.innerWidth - document.body.offsetWidth + "px";
    this.fixBlocks.forEach((el) => {
      el.style.paddingRight = paddingOffset;
    });
    document.body.style.paddingRight = paddingOffset;
  }

  unlockPadding() {
    this.fixBlocks.forEach((el) => {
      el.style.paddingRight = "0px";
    });
    document.body.style.paddingRight = "0px";
  }
}

document.querySelectorAll(".modal").forEach((modal) => {
  const instance = new Modal(
    {
      onOpen: (modal) => {},
      onClose: () => {},
    },
    modal
  );
  modal.querySelector(".modal__container").modalInstance = instance;
});

document.addEventListener("click", function (e) {
  const clickedElement = e.target.closest("[data-path]");
  if (clickedElement) {
    const target = clickedElement.dataset.path;
    const modalContainer = document.querySelector(`[data-target="${target}"]`);
    const instance = modalContainer.modalInstance;
    instance.open();
  }

  if (e.target.closest(".modal__close")) {
    const modalContainer = e.target.closest(".modal__container");
    const instance = modalContainer.modalInstance;
    instance.close();
  }
});




