import './components/burger.js';
import './components/animation.js';
import './components/sliders.js';
// import './components/select.js';
import './components/accordion.js';
import './components/modals.js';
// import './components/replaceEl.js';
import './components/dropDowns.js';
// import './components/links.js';
// import './components/hideSticky.js';
// import './components/lightGallery.js';
// import './components/phoneMask.js';
import './components/tabs.js';
import './components/coockie.js';
// import './components/closeAlert.js';
// import './components/choices.js';
// import './components/showPassword.js';
import './components/smoothScroll.js';
import './components/counterNumber.js';









