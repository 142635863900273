document.addEventListener('DOMContentLoaded', () => {

    function closeDropDown(elem) {
        elem.classList.remove('active');
    }

    document.querySelectorAll('.drop-down').forEach((elem) => {
        elem.addEventListener('click', (e) => {
            e.stopPropagation();
            const dropBody = elem.querySelector('.drop-down__menu')
            dropBody.classList.toggle('active');
            dropBody.querySelectorAll('.list__item').forEach((link) => {
                link.addEventListener('click', (e) => { 
                    e.stopPropagation();
                    closeDropDown(dropBody);     
                });
            })
        });
    })
})